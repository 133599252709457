// import apiMock from './api-mock';
import axios from 'axios';

import LocalStorageService from "../../services/localStorage";

/**
 * Constrói uma query string a partir de um objeto 
 * @param {Object} dados objeto contendo chave e valor 
 * @returns 
 */
export const buildQueryString = (dados) => {
  if (!dados) return '';

  const params = Object.keys(dados).filter(
    key => !['', undefined].includes(dados[key])
  );
  if (!params.length) return '';

  const qryStrings = '?' + params.map(
    param => `${param}=${dados[param]}`
  ).join('&');
  return qryStrings;
} 


const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(
  config => {
    const userData = LocalStorageService.getUserDataLocal()
    const { token, refresh_token } = userData;

    config.headers['Authorization'] = 'Bearer ' + token;
    config.headers['refresh_token'] = refresh_token;
    

    return config;
  },
  error => Promise.reject(error)
)

api.interceptors.response.use(response => {
  return response;
}, function (error) {
  return Promise.reject(error);
})


export default api;