export const functionsColumns = [
    {
        label: 'Tipo',
        name: 'nomeFuncao',
    },
    {
        label: 'Horas',
        name: 'horas',
    },
    {
        label: 'Taxa de custo',
        name: 'taxaCustoVenda',
    },
    {
        label: 'Total',
        name: 'totalFuncaoMes'
    }
]

export const costsColumns = [
    {
        label: 'Tipo',
        name: 'tipoCusto',
    },
    {
        label: 'Descrição',
        name: 'descricao',
    },
    {
        label: 'Valor original',
        name: 'valorOriginal',
    },
    {
        label: 'Valor total',
        name: 'valor',
    },
]

export const totalFunctionsColumns = [
    {
        label: 'Função',
        name: 'nomeFuncao',
    },
    {
        label: 'Total de horas',
        name: 'totalHora',
    },
    {
        label: 'Taxa de custo',
        name: 'taxaCustoVenda',
    },
    {
        label: 'Valor total',
        name: 'valorTotal',
    }
]

export const onlyMonthsColumns = [
    {
        label: 'Valor total',
        name: 'valorTotal',
    }
]

export const totalCostsColumns = [
    {
        label: 'Tipo',
        name: 'tipoCusto',
    },
    {
        label: 'Descrição',
        name: 'descricao',
    },
    {
        label: 'Valor total',
        name: 'totalValor',
    },
]
