import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row, Button, Pagination, Table } from 'react-bootstrap';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa"

import { FormBuilder, FormStateBuilder } from '../../../components/FormBuilder';

import PageContainer from '../../../components/PageContainer';
import Loading from '../../../components/Loading';

import ListLayout from '../../../components/ListLayout';
import IssuesListInfo from "./IssuesListInfo";
import ColaboradoresListInfo from "./ColaboradoresListInfo";
import CustosListInfo from './CustosListInfo';
import FiltroInfo from "./FiltroInfo";
import HorasDetail from "./HorasDetail";
import HeaderDetailColaboradores from "./HeaderDetailColaboradores";
import HeaderDetailTarefas from "./HeaderDetailTarefas";
import HeaderDetailCustos from './HeaderDetailCustos';

import ErrorRequestService from '../../../services/errorRequest';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import { ProjetoViewHelpModal } from './ProjetoViewHelpModal';
import CustomModal from '../../../components/CustomModal';
import CustoDetail from './CustoDetail';
import * as R from 'ramda';
import axios from 'axios';
import CompararCustos from './CompararCustos';
import CustosPrevistosService from '../../../services/api/CustosPrevistosService';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import NothingToShow from '../../../components/NothingToShow';

function ProjetoView() {
    const match = useRouteMatch();

    const { id, key } = match.params;

    const [dataProjectDetail, setDataProjectDetail] = useState({
        pages: 1,
        timeSpentBillable: 0,
        timeSpentTotal: 0,
        timeSpentUser: [],
        timesheet: [Array(0)],
        total: 0
    });
    const [dataProjectCosts, setDataProjectCosts] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingRecalculoCustoTotal, setIsLoadingRecalculoCustoTotal] = useState(true);
    const [formFields, setFormFields] = useState(null);
    const [formData, setFormData] = useState(null);
    const [origData, setOrginData] = useState(null);

    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());

    const [cotacoes, setCotacoes] = useState({})

    const [itemsPageIssues, setItemsPageIssues] = useState([]);
    const [timesheet, setTimesheet] = useState([]);

    const [showTables, setShowTables] = useState(true);
    const [custosPrevistosData, setCustosPrevistosData] = useState([]);

    const [displayTarefas, setDisplayTarefas] = useState("d-none");

    const [arrayTimeSheetForPagination, setArrayTimeSheetForPagination] = useState([])
    const handleShowHideTarefas = () =>{
        setDisplayTarefas((prev) => (prev === "d-none" ? "" : "d-none"));
    }

    const mask = (value) => {
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

        const options = { minimumFractionDigits: 2 }

        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )

        return result
    }

    const getCotacoes = async () => {
        const config = {
            method: 'get',
            url: `https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL`
          };

          const result = await axios(config);
          setCotacoes({
              BRL: '0,00',
              USD: mask(parseFloat(result.data['USDBRL'].bid).toFixed(2)),
              EUR: mask(parseFloat(result.data['EURBRL'].bid).toFixed(2))
          })
    }

    const handlerFilter = async () => {
        const startAt = 0;
        const initialDateAdjusted = format(utcToZonedTime(initialDate,  'America/Sao_Paulo' ), 'dd/MM/yyyy');
        const finalDateAdjusted = format(utcToZonedTime(finalDate,  'America/Sao_Paulo' ), 'dd/MM/yyyy');

        try {
            setIsLoading(true);
            setIsLoadingRecalculoCustoTotal(true)

            let ids = id.split(",")
            let params = key.split(",").map((key, i) => {
                return `${key}-${ids.at(i)}`
            })

            const resp = (await ProjetosApiService.getDetailProject(params.join(","), initialDateAdjusted, finalDateAdjusted)).data;
            const respCosts = await ProjetosApiService.getCustosProjeto(
                key,
                format(new Date(initialDate), 'dd-LLL-yyyy'),
                format(new Date(finalDate), 'dd-LLL-yyyy')
            );

            const semArraysVazios = cleanArray(resp.timesheet);
            const flatData = semArraysVazios.flat();
            const groupedData = splitArrayIntoChunks(flatData, 10);

            setArrayTimeSheetForPagination(groupedData);
            buildPagination(groupedData.length, 0);
            setTimesheet(groupedData[0] || []);

            setDataProjectDetail(resp);
            setDataProjectCosts(respCosts.data);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert(errorMsg);
        } finally {
            setIsLoading(false);
            setIsLoadingRecalculoCustoTotal(false)
        }
    }

    const getDataList = useCallback(async () => {
        try {
            setIsLoading(true);

            const formFields = FiltroInfo.getFormFields();
            const formData = FormStateBuilder(formFields);

            setOrginData(formData);
            setFormFields(formFields);
            setFormData(formData);

            // await handlerFilter();
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert(errorMsg);

        } finally {
            setIsLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buildPagination = (pages, startAt = 0) => {
        const arrPagesNumber = [];

        for (let number = 0; number < pages; number++) {
            arrPagesNumber.push(
                <Pagination.Item key={number} active={number === +startAt}>
                    {number}
                </Pagination.Item>,
            );
        }

        setItemsPageIssues(arrPagesNumber);
    };

    const navPagination = (i) => {
        const index = parseInt(i, 10);
        if (isNaN(index) || index < 0 || index >= arrayTimeSheetForPagination.length) return;

        setTimesheet(arrayTimeSheetForPagination[index] || []);
        buildPagination(arrayTimeSheetForPagination.length, index);
    }

    const cleanArray = (data) => {
        return data.filter((subArray) => subArray.length > 0);
    };

    const splitArrayIntoChunks = (data, chunkSize = 20) => {
        const result = [];
        let chunk = [];
    
        for (let i = 0; i < data.length; i++) {
            chunk.push(data[i]);
    
            if (chunk.length === chunkSize || i === data.length - 1) {
                result.push(chunk);
                chunk = [];
            }
        }
    
        return result;
    };

    useEffect(() => {
        const semArraysVazios = cleanArray(dataProjectDetail.timesheet);
        
        const flatData = semArraysVazios.flat();
        const groupedData = splitArrayIntoChunks(flatData);
        setArrayTimeSheetForPagination(groupedData);

        if (groupedData.length > 0) {
            buildPagination(groupedData.length, 0);
            setTimesheet(groupedData[0]);
        }
    }, [dataProjectDetail])

    const onChangePeriodo = async (ev) => {
        const { value, name } = ev.target;
        const newform = await { ...formData, [name]: value };

        const initialDateFormat = typeof newform.initialDate === 'string' ? `${newform.initialDate}T00:00` : newform.initialDate

        setInitialDate(initialDateFormat);

        const getDataFormat = new Date(newform.initialDate+"T00:00:00");

        const dayInitialChange = getDataFormat.getDate();
        const monthInitialChange = getDataFormat.getMonth()+1;
        const yearInitialChange = getDataFormat.getFullYear();

        const dayInitialOld = new Date(initialDate).getDate();
        const monthInitialOld = new Date(initialDate).getMonth()+1;
        const yearInitialOld = new Date(initialDate).getFullYear();

        if(dayInitialOld == dayInitialChange && monthInitialOld == monthInitialChange && yearInitialOld == yearInitialChange) {
            setFinalDate(typeof newform.finalDate === 'string' ? `${newform.finalDate}T00:00` : newform.finalDate);
        } else {                       
            const month = getDataFormat.getMonth()+1;
            const year = getDataFormat.getFullYear();
            const day = new Date(year, month, 0).getDate();

            if(month < 10) {
                setFinalDate(newform.finalDate = `${year}-0${month}-${day}`);
            } else {
                setFinalDate(newform.finalDate = `${year}-${month}-${day}`);
            }
        }

        if (!newform.finalDate.includes('T')) {
            setFinalDate(newform.finalDate+"T00:00");
        }

        setFormData(newform);
    }

    const getIssuesRows = useCallback(() => {
        if (!dataProjectDetail) return [];
        const rows = timesheet.map(item => {
            const row = {
                data: IssuesListInfo.columns.map(col => {
                    let value = item[col.name];

                    if (`${item.timeLogged}`.split('.').length > 1) {
                        item.timeLogged = item.timeLogged.toLocaleString('pt-br', { hour: '2-digit' })
                    }

                    return {
                        value,
                        className: col.className
                    }
                })
            }
            return row;
        });
        return rows;
    }, [dataProjectDetail, timesheet]);

    const getColaboradoresRows = useCallback(() => {
        if (!dataProjectDetail) return [];
        const rows = dataProjectDetail.timeSpentUser.map(item => {
            const row = {
                data: ColaboradoresListInfo.columns.map(col => {
                    let value = item[col.name];

                    if (`${item.timeLogged}`.split('.').length > 1) {
                        item.timeLogged = item.timeLogged.toLocaleString('pt-br', { hour: '2-digit' })
                    }

                    if (col.name === 'custoHH' || col.name === 'custoTotal') {
                        value = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                    }

                    if (col.name === 'addCustoTotal') {
                        return {
                            actions: {
                                type: "checkbox",
                                checked: item[col.name],
                                onclick: async (item) => {
                                    const itemAdded = dataProjectDetail.timeSpentUser.find(obj => obj.user === item.id)

                                    setIsLoadingRecalculoCustoTotal(true)
                                    await new Promise(resolve => setTimeout(resolve, 50))

                                    if (itemAdded && item.checked) {
                                        const calculo = dataProjectDetail.total + itemAdded.custoTotal
                                        dataProjectDetail.total = calculo
                                        setDataProjectDetail(dataProjectDetail)
                                    } else {
                                        const calculo = dataProjectDetail.total - itemAdded.custoTotal
                                        dataProjectDetail.total = calculo
                                        setDataProjectDetail(dataProjectDetail)
                                    }

                                    setIsLoadingRecalculoCustoTotal(false)
                                }
                            }
                        }
                    }
                    
                    return {
                        value,
                        className: col.className
                    }
                })
            }
            return row;
        });

        return rows;
    }, [dataProjectDetail]);

    const rowClickCusto = (item) => {
        CustomModal.show({
            title: `Detalhes do Custo -
            Período ${format(new Date(item.periodo_inicio), 'dd/LLL-yyyy')} a ${format(new Date(item.periodo_fim), 'dd/LLL-yyyy')}`,
            body: (
                <CustoDetail
                    item={item}
                    cotacoes={cotacoes}
                    mask={mask}
                    onCancel={() => CustomModal.hide()}
                    handlerFilter={handlerFilter}
                />
            )
        })
    }

    const getCustosRows = useCallback(() => {
        if(dataProjectCosts.length <= 0) return []

        const MOEDA = {
            "BRL": "Real",
            "USD": "Dólar",
            "EUR": "Euro"
        }

        const TIPO_CUSTO = {
            "INFRA_CLOUD": "Infraestrutura em Cloud",
            "OUTROS_SOFTWARES": "Outros softwares",
            "HORAS_EXTRAS": "Horas extras",
            "FORNECEDORES_TERCEIROS": "Fornecedores terceiros",
            "OUTROS": "Outros",
        }

        const rows = dataProjectCosts.map(item => {
            const row = {
                onClick: () => rowClickCusto(item),
                data: CustosListInfo.columns.map(col => {
                    let value = item[col.name];

                    if (col.name === 'valor') {
                        // value = Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                        value = mask(value)
                    }

                    if (col.name === 'moeda') value = MOEDA[value];

                    if (col.name === 'tipo_custo') value = TIPO_CUSTO[value];

                    if (col.name === 'valor_convertido') {
                        value = item.cotacao > 0 ?
                            R.multiply(item.valor, item.cotacao).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            :
                            Number(item.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    }

                    return {
                        value,
                        className: col.className
                    }
                })
            }
            return row;
        })

        return rows;
    }, [dataProjectCosts, rowClickCusto]);

    const getCustosPrevistos = async () => {
        try {
            setIsLoading(true);

            const resp = await CustosPrevistosService.getAll(key);

            let newData = {}

            resp?.data?.funcoes?.forEach(funcao => {
                if (!newData[funcao.indice]) {
                    newData[funcao.indice] = {
                        mesAno: funcao.mes_ano,
                        totalFuncao: [Number(funcao.custo_total_funcao)],
                        totalCustoIndireto: {}
                    }
                } else {
                    newData[funcao.indice].totalFuncao.push(Number(funcao.custo_total_funcao))
                }
            })

            resp?.data?.custos?.forEach(custo => {
                if (!newData[custo.indice]) {
                    newData[custo.indice] = {
                        mesAno: custo.mes_ano,
                        totalCustoIndireto: {
                            [custo.tipo]: Number(custo.valor_custo_indireto)
                        },
                        totalFuncao: []
                    }
                } else if (!newData[custo.indice].totalCustoIndireto[custo.tipo]) {
                    newData[custo.indice].totalCustoIndireto[custo.tipo] = Number(custo.valor_custo_indireto)
                } else {
                    newData[custo.indice].totalCustoIndireto[custo.tipo] =
                        R.sum([newData[custo.indice].totalCustoIndireto[custo.tipo], Number(custo.valor_custo_indireto)])
                }
            })

            for (let key in newData) {
                const newTotalFuncao = R.sum(newData[key].totalFuncao)
                const newTotalCusto = R.sum(Object.values(newData[key].totalCustoIndireto))

                newData[key] = {
                    ...newData[key],
                    totalFuncao: newTotalFuncao,
                    total: R.sum([newTotalFuncao, newTotalCusto])
                }
            }

            setCustosPrevistosData(newData)
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert(errorMsg);
        }  finally {
            setIsLoading(false);
        }
    }

    const [sortDirection, setSortDirection] = useState({});
    const [ordenacaoColaborador, setOrdenacaoColaborador] = useState(dataProjectDetail.timeSpentUser);

    const handleOrdenar = (key) => {

        let direction = sortDirection[key] || "cima";
        direction = direction === "cima" ? "baixo" : "cima";
        setSortDirection({ ...sortDirection, [key]: direction });

        const sortedColaboradores = [...dataProjectDetail.timeSpentUser].sort((a, b) => {
            if (key === "Colaborador") {
                return direction === "cima" ? a.user.localeCompare(b.user) : b.user.localeCompare(a.user);
            } else if (key === "Horas Trabalhadas") {
                const parseNumber = (str) => {
                    str = str.toString()
                    return parseFloat(str.replace(/,/g, '.'));
                };
                return direction === "cima" ? parseNumber(a.timeLogged) - parseNumber(b.timeLogged) : parseNumber(b.timeLogged) - parseNumber(a.timeLogged);
            } else if (key === "Custo Médio Hora") {
                return direction === "cima" ? a.custoTotal - b.custoTotal : b.custoTotal - a.custoTotal;
            } else if (key === "Custo Total") {
                return direction === "cima" ? a.custoHH - b.custoHH : b.custoHH - a.custoHH;
            } else if (key === "Projeto") {
                return direction === "cima" ? a.projeto.localeCompare(b.projeto) : b.projeto.localeCompare(a.projeto);
            } 
        });

        setOrdenacaoColaborador(sortedColaboradores);
    };

    const SortIcon = ({ column, sortDirection }) => {
        if (!sortDirection[column]) {
            return <FaSort className="ml-1" style={{ opacity: 0.3 }} />;
        }
        return sortDirection[column] === "cima" ? 
            <FaSortUp className="ml-1" /> : 
            <FaSortDown className="ml-1" />;
    };

    useEffect(() => {
        setOrdenacaoColaborador(dataProjectDetail.timeSpentUser)
    }, [dataProjectDetail.timeSpentUser]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getDataList()
        getCotacoes()
        getCustosPrevistos()
    }, []);

    const title = `Projeto ${key}`;

    return (
        <div style={{width: "100%", maxWidth:"calc(100vw - 260px)"}}>
        <PageContainer
            title={title}
            infoButton={<ProjetoViewHelpModal />}
        >
            {formFields &&
                <Row>
                    <Col>
                        <FormBuilder
                            formFields={formFields}
                            formData={formData}
                            onChange={onChangePeriodo}
                            initialValues={origData}
                            disabledForm={isLoading}
                        />
                        <Button variant="outline-primary" onClick={() => handlerFilter()} disabled={isLoading}>Filtrar</Button>
                    </Col>

                    <Col>
                        <HorasDetail
                            isLoading={isLoading}
                            isLoadingRecalculoCustoTotal={isLoadingRecalculoCustoTotal}
                            dataProjectDetail={dataProjectDetail}
                            dataProjectCosts={dataProjectCosts}
                            project={key}
                            periodoInicio={format(new Date(initialDate), 'dd-LLL-yyyy')}
                            periodoFim={format(new Date(finalDate), 'dd-LLL-yyyy')}
                            handlerFilter={handlerFilter}
                            showTables={showTables}
                            changeCompareCustos={() => setShowTables(!showTables)}
                            formData={formData}
                        />
                        <CustomModal />
                    </Col>
                </Row>
            }

            {isLoading
                ? <Loading message="Carregando..." />
                :
                showTables ? (
                    <>
                        <HeaderDetailColaboradores dataProjectDetail={dataProjectDetail} />
                        {/* <ListLayout columns={ColaboradoresListInfo.columns} rows={getColaboradoresRows()} /> */}

                        <Table striped bordered hover>
                            <thead>
                                <tr className="list-row">
                                    {ColaboradoresListInfo.columns.map((item, index) => (
                                        <th
                                            key={index}
                                            onClick={() => {handleOrdenar(item.label)}}
                                            className={`list-header`}
                                            style={{ cursor: 'pointer' }}
                                            width={item.width}
                                        >
                                            {item.label} <SortIcon column={item.label} sortDirection={sortDirection} />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {ordenacaoColaborador.length === 0 && <tr><td colSpan={6}> <NothingToShow /> </td></tr>}
                                {ordenacaoColaborador.map((item, index) => (
                                    <tr key={index} className="list-row">
                                        <td className="list-row-cell">{item.user}</td>
                                        <td className="list-row-cell">{item.timeLogged.toLocaleString('pt-br', { hour: '2-digit' })}</td>
                                        <td className="list-row-cell">R$ {item.custoHH.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                                        <td className="list-row-cell">R$ {item.custoTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</td>
                                        <td className="list-row-cell">{item.projeto}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <HeaderDetailCustos />
                        <ListLayout columns={CustosListInfo.columns} rows={getCustosRows()} />
                        <div style={{display:"flex", alignItems:"baseline", width:"fit-content"}}>
                            <HeaderDetailTarefas display={displayTarefas} timesheet={timesheet} />
                            <div style={{display:"flex", alignItems:"center", cursor: "pointer", marginLeft:"8px"}}  onClick={() => handleShowHideTarefas()}>
                                {displayTarefas ? <FaArrowAltCircleDown/>  : <FaArrowAltCircleUp/>}
                                <span style={{marginLeft:"8px"}}>{!displayTarefas? "Fechar" : "Abrir"}</span>
                            </div>
                        </div>
                        <div className={displayTarefas}>
                            <ListLayout columns={IssuesListInfo.columns} rows={getIssuesRows()} />
                            <Pagination size="sm" style={{display: "flex", flexWrap:"wrap"}} onClick={(e) => navPagination(e.target.textContent)}>{itemsPageIssues}</Pagination>
                        </div>
                        
                        
                    </>
                ) : (
                    <CompararCustos
                        dataProjectDetail={dataProjectDetail}
                        dataProjectCosts={dataProjectCosts}
                        custosPrevistosData={custosPrevistosData}
                        changeCompareCustos={() => setShowTables(!showTables)}
                    />
                )
            }
        </PageContainer>
        </div>
    )
}

export default ProjetoView;
