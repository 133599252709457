import React from "react";

const FluxoCaixaView = () => {
    return (
        <div>
            <h1>Fluxo de Caixa</h1>
        </div>
    )
};

export default FluxoCaixaView;