import React, { useState, useEffect } from 'react'
import { totalCostsColumns, onlyMonthsColumns,totalFunctionsColumns } from '../ProjetoPrevistoViewInfo';
import ListLayout from '../../../../components/ListLayout';
import * as R from 'ramda';
import { Card } from 'react-bootstrap';
import { getTipoCusto, getTotais } from '../formatData';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function TableTotal({ rowsDataFuncoes, rowsDataCustos, tabs, valorVenda, totalValorVenda }) {
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);

    const transformNumber = (value) => {
        return Number(value?.replaceAll('.', '').replace(',', '.'));
    }

    const getListHeader = (type) => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }

        const columnsHeader = getColumns(type)

        columnsHeader.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const getFunctionsRows = () => {
        const totaisFuncoes = getTotais(rowsDataFuncoes, rowsDataCustos);

        const columns = getColumns('funcoes');

        let rows = rowsDataFuncoes.map(item => {
            const row = {
                data: columns.map(col => {
                    const totalHoras = R.sum(Object.values(item.horas).filter(value => !isNaN(transformNumber(value))).map(transformNumber))

                    if (col.name === 'mes') {
                        return {
                            value: transformNumber(item.horas[col.month]) ?
                                transformNumber(item.horas[col.month]).toString().replace('.', ',')
                                : '-'
                        }
                    }

                    if (col.name === 'totalHora') {

                        return {
                            value: totalHoras.toString().replace('.', ',')
                        }
                    }

                    if (col.name === 'taxaCustoVenda') {
                        return {
                            value: transformNumber(item[col.name])
                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }
                    }

                    if (col.name === 'valorTotal') {
                        return {
                            value: R.multiply(totalHoras, transformNumber(item.taxaCustoVenda))
                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }
                    }

                    return {
                        value: item[col.name]
                    }
                })
            }
            return row;
        })

        const classNameTotal = ['font-weight-bold'];

        if (rows.length > 0) {
            const arrInitialTotal = Array(columns.length - 4).fill(0);

            const totaisMes = rows.reduce((total, item) => {
                for (let i = 1; i < item.data.length - 3; i++) {

                    total[i - 1] = R.sum([total[i - 1], (transformNumber(item.data[i].value) || 0)])
                }
                return total;
            }, arrInitialTotal)

            rows.push({
                data: [
                    { value: 'Total Horas', className: classNameTotal },
                    ...totaisMes.map(item => ({ value: item.toString().replace('.', ','), className: classNameTotal })),
                    { value: totaisFuncoes.totalHoras.toString().replace('.', ','), className: classNameTotal },
                    { value: "", className: classNameTotal },
                    { value: "", className: classNameTotal}
                ]
            });

            const summedResult = rowsDataFuncoes.reduce((acc, item) => {
                Object.keys(item.totalFuncaoMes).forEach((key) => {
                    acc[key] = (parseFloat(acc[key] || 0) + parseFloat(item.totalFuncaoMes[key])).toFixed(2);
                });
                return acc;
            }, {});
    
            const formattedResult = [
                { value: "Total HH", className: classNameTotal },
                ...Object.values(summedResult).map(value => ({
                    value: "R$ "+Number(value).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    className: classNameTotal
                })),
                { value: "", className: classNameTotal},
                { value: "", className: classNameTotal}
            ];
        
            const totalSum = Object.values(summedResult).reduce((acc, val) => acc + parseFloat(val), 0).toFixed(2);
    
            formattedResult.push({ value: "R$ "+Number(totalSum).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            className: classNameTotal });

            rows.push({
                data: formattedResult
            });
        }

        return rows;
    }

    const getFunctionsDetailRows = () => {
        const summedResult = rowsDataFuncoes.reduce((acc, item) => {
            Object.keys(item.totalFuncaoMes).forEach((key) => {
                acc[key] = (parseFloat(acc[key] || 0) + parseFloat(item.totalFuncaoMes[key])).toFixed(2);
            });
            return acc;
        }, {});

        const formattedResult = [
            // { value: "Total", className: ["font-weight-bold"] },
            ...Object.values(summedResult).map(value => ({
                value: "R$ "+Number(value).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
                className: ["font-weight-bold"]
            }))
        ];
    
        const totalSum = Object.values(summedResult).reduce((acc, val) => acc + parseFloat(val), 0).toFixed(2);

        formattedResult.push({ value: "R$ "+Number(totalSum).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }),
        className: ["font-weight-bold"] });

        return [{data: formattedResult}];
    };

    const sumFunctionDetailLists = (list1, list2) => {
        if (list1.length === 0) return list2;
        if (list2.length === 0) return list1;
    
        const funcao = list1[0]?.data;
        const filteredList2 = list2[list2.length - 1]?.data;
        const custo = filteredList2.slice(2); // Ignora as duas primeiras linhas da list2
    
        let funcaoModified = [...funcao];
    
        // Se list2 (depois de cortar as 2 primeiras linhas) for maior, adiciona valores 0 antes da última linha de list1
        if (custo.length > funcao.length) {
            const diff = custo.length - funcao.length;
            const lastItem = funcaoModified.pop(); // Remove a última linha temporariamente
    
            for (let i = 0; i < diff; i++) {
                funcaoModified.push({ value: "0", className: ["font-weight-bold"] });
            }
    
            funcaoModified.push(lastItem); // Adiciona a última linha de volta
        }
    
        const maxLength = Math.max(funcaoModified.length, custo.length);
    
        return [{
            data: Array.from({ length: maxLength }, (_, index) => {
                const value1 = funcaoModified[index]?.value || "0";
                const value2 = custo[index]?.value || "0";
    
                if (isNaN(parseFloat(value1.replace(/[^0-9,-]/g, "").replace(",", "."))) &&
                    isNaN(parseFloat(value2.replace(/[^0-9,-]/g, "").replace(",", ".")))) {
                    return { value: value1 || value2, className: ["font-weight-bold"] };
                }
    
                const num1 = parseFloat(value1.replace(/[^0-9,-]/g, "").replace(",", ".")) || 0;
                const num2 = parseFloat(value2.replace(/[^0-9,-]/g, "").replace(",", ".")) || 0;
                const sum = num1 + num2;
    
                const formattedValue = `R$ ${sum.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}`;
    
                return { value: formattedValue, className: ["font-weight-bold"] };
            })
        }];
    };

    const getCostsRows = () => {
        const totaisCustos = getTotais(rowsDataFuncoes, rowsDataCustos);

        const columns = getColumns('custos');

        let rows = rowsDataCustos.map(item => {
            const row = {
                data: columns.map(col => {
                    const totalValor = R.sum(Object.values(item.valor)
                        .filter(value => !isNaN(transformNumber(value)))
                        .map(transformNumber))

                    if (col.name === 'mes') {
                        const value = transformNumber(item.valor[col.month]) || '-'

                        return {
                            value: value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }
                    }

                    if (col.name === 'totalValor') {
                        return {
                            value: totalValor
                                .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                        }
                    }

                    if (col.name === 'tipoCusto') {
                        return { value: getTipoCusto(item[col.name]) }
                    }

                    return {
                        value: item[col.name]
                    }
                })
            }
            return row;
        })

        const classNameTotal = ['font-weight-bold'];

        if (rows.length > 0) {
            const arrInitialTotal = Array(columns.length - 3).fill(0);

            const totaisMes = rows.reduce((total, item) => {
                for (let i = 2; i < item.data.length - 1; i++) {
                    const valor = parseFloat(
                        item.data[i].value.replace(/R\$/g, '').replace(/\./g, '').replace(',', '.')
                    ) || 0;
                    total[i - 2] += valor;
                }
                return total;
            }, arrInitialTotal);

            rows.push({
                data: [
                    { value: 'Total', className: classNameTotal },
                    { value: '', className: classNameTotal },
                    ...totaisMes.map(item => ({ value: item.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal })),
                    { value: totaisCustos.totalCustoIndireto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal }
                ]
            })
        }

        return rows;
    }

    const getValorVendaRows = () => {

        const monthAddClear = tabs.length - valorVenda.length;
        
        const formatCurrency = value => {
            return `R$ ${parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
        };
        
        valorVenda.sort((a, b) => {
            const [mesA, anoA] = a.mesAno.split('-').map(Number);
            const [mesB, anoB] = b.mesAno.split('-').map(Number);
            return anoA !== anoB ? anoA - anoB : mesA - mesB;
        });
        
        const transformed = {
            data: valorVenda.map(item => ({
                value: formatCurrency(item.valor),
                className: ["font-weight-bold"]
            }))
        };

        if (monthAddClear > 0) {
            for (let i = 0; i < monthAddClear; i++) {
                transformed.data.push({
                    value: "-",
                    className: ["font-weight-bold"]
                });
            }
        }
        
        const total = valorVenda.reduce((acc, item) => acc + parseFloat(item.valor), 0);
        
        transformed.data.push({
            value: formatCurrency(total),
            className: ["font-weight-bold"]
        });

        return [transformed];
    }

    const getColumns = (type) => {
        const monthsToAdd = tabs.map(tab => ({
            label: `M${tab}`,
            name: 'mes',
            month: tab,
        }));

        const columns = {
            funcoes: [...totalFunctionsColumns],
            onlyMonths: [...onlyMonthsColumns],
            custos: [...totalCostsColumns]
        }

        columns.funcoes.splice(1, 0, ...monthsToAdd);
        columns.onlyMonths.splice(0, 0, ...monthsToAdd);
        columns.custos.splice(2, 0, ...monthsToAdd);

        return columns[type];
    }

    const parseValue = (value) => {
        return parseFloat(value.replace('R$', '').replace('.', '').replace(',', '.'));
    };

    const rowsNulabel = (sumFunctionDetailLists, valorVendaRows) => {
        let maxValues = [];

        maxValues = sumFunctionDetailLists[0]?.data.map((sumItem, index) => {
            const sumValue = parseValue(sumItem.value);
            const vendaValue = valorVendaRows[0].data[index]?.value;
        
            const maxValue = Math.max(sumValue, vendaValue);
            
            return {
            value: `R$ ${maxValue.toFixed(2).replace('.', ',')}`,
            className: ["text-transparent"]
            };
        });

        return [
            {
            data: maxValues || []
            }
        ];
    };

    const detailLists = sumFunctionDetailLists(getFunctionsDetailRows(), getCostsRows());
    const valorVendaRows = getValorVendaRows();

    const rowsHeaderNulabel = rowsNulabel(detailLists, valorVendaRows);

    const [isFunctionsOpen, setIsFunctionsOpen] = useState(false);
    const [isCostsOpen, setIsCostsOpen] = useState(false);

    const toggleFunctions = () => setIsFunctionsOpen(!isFunctionsOpen);
    const toggleCosts = () => setIsCostsOpen(!isCostsOpen);

    return (
        <div className='mt-3'>
            <div className='table-responsive'>
                <div className='table-responsive'>
                    <table style={{ backgroundColor: "rgb(225, 234, 243)" }} className='table table-content table table-striped table-hover'>
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                {getColumns('onlyMonths').map((item, index) => (
                                    <th colSpan={item.colspan} style={{ cursor: "pointer" }} className='list-header' key={index}>{item.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ backgroundColor: "rgb(225, 234, 243)" }} className='list-row'>
                                <td>Total de Custo</td>
                                {detailLists[0]?.data.map((item, index) => (
                                    <td className={item.className} key={index}>{item.value}</td>
                                ))}
                            </tr>
                        </tbody>
                        <tbody>
                            <tr style={{ backgroundColor: "rgb(225, 234, 243)" }} className='list-row'>
                                <td>Valor Venda</td>
                                {valorVendaRows[0]?.data.map((item, index) => (
                                    <td className={item.className} key={index}>{item.value}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* <h4>Total</h4> */}
            {/* <ListLayout columns={getListHeader('onlyMonths')} rows={rowsHeaderNulabel} /> */}

            {/* <h5>Total de Custo</h5>
            <ListLayout rows={sumFunctionDetailLists(getFunctionsDetailRows(), getCostsRows())} />

            <h5 className='mt-3'>Valor Venda</h5>
            < ListLayout rows={getValorVendaRows()} /> */}


            <h4 className="mt-3" onClick={toggleFunctions} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <span className='mr-2'>Custo HH</span>
                {isFunctionsOpen ? (
                '▴'
                ) : (
                '▾'
                )}
            </h4>
            {isFunctionsOpen && (
                <ListLayout columns={getListHeader('funcoes')} rows={getFunctionsRows()} />
            )}

            <h4 className="mt-5" onClick={toggleCosts} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <span className='mr-2'>Custos Indiretos</span>
                {isCostsOpen ? (
                '▴'
                ) : (
                '▾'
                )}
            </h4>
            {isCostsOpen && (
                <ListLayout columns={getListHeader('custos')} rows={getCostsRows()} />
            )}

            {/* <Card className='p-3 mt-3'>
                <h5>Total Geral de Custo</h5>
                <p>
                    { getTotais(rowsDataFuncoes, rowsDataCustos)
                    .total
                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }
                </p>
            </Card> */}

            {/* <Card className='p-3 mt-3'>
                <h5>Total Valor Venda</h5>
                <p>{totalValorVenda ? parseFloat(totalValorVenda).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</p>
            </Card> */}
        </div>
    )
}

export default TableTotal
