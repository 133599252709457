import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Button, Image } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';
import useAuth from '../../../context/AuthContext';
import useAlertNotification from '../../../context/AlertNotificationContext';
import useTheme from '../../../context/ThemeContext';
import LoginValidation from '../../../services/validation/LoginValidation';
import UtilService from '../../../services/util';
import AuthApiService from '../../../services/api/AuthApiService';
import './LoginPage.css';
import axios from 'axios';

const INITIAL_PAGE_PATH = "/dashboard";

function LoginPage() {
  const title = "Login";
  UtilService.setTitle(title);

  const [isLoading, setIsLoading] = useState(false);

  const { user, signIn } = useAuth();
  const alert = useAlertNotification();
  const themeContext = useTheme();
  const history = useHistory();
  const match = useRouteMatch();

  const handleGoogleAuth = () => {
    window.history.pushState({}, "", "/login");
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${new URLSearchParams({
      client_id: process.env.REACT_APP_CLIENTE_ID_GOOGLE,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      response_type: 'code',
      scope: 'profile openid email',
      access_type: 'offline',
      include_granted_scopes: 'true',
    }).toString()}`;
    window.location.href = authUrl;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      (async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login/google`, {
            code,
          });

          const { token, refresh_token, user } = response.data;
          signIn({ token, refresh_token, user });
          history.push(INITIAL_PAGE_PATH);
        } catch (error) {
            window.history.pushState({}, "", "/login");

          console.error('Erro ao autenticar com o Google:', error);
          alert.error('Erro ao autenticar com o Google.');
        }
      })();
    }
  
  }, [alert, history, signIn]);

  if (!!user) return <Redirect to={INITIAL_PAGE_PATH} />;

  const onSubmit = async (form, actions) => {
    try {
      setIsLoading(true);
      const validation = await LoginValidation.validate(form);
      if (!validation.isValid) {
        const { errors } = validation;
        const errorsTxt = Object.keys(errors).map(key => errors[key]).join('\n');
        alert.error(errorsTxt);
        return;
      }

      const authorization = UtilService.encodeUtf8AsBase64(JSON.stringify(form));
      const response = await AuthApiService.sendLogin(authorization);
      if (!response.data) {
        alert.error("Problema ao efetuar login.");
        return;
      }

      const { errors, token, refresh_token, user } = response.data;

      if (errors) {
        const msgError = [];
        Object.keys(errors).forEach(key => {
          const errStr = errors[key].map(item => item.errorMessage).join('\n');
          msgError.push(errStr);
        });
        console.log("[ERROR] [LoginPage.onSubmit]:", { errors, msgError });
        alert.error(msgError.join('\n'));
        return;
      }

      signIn({ token, refresh_token, user });
      alert.success(`Bem-vindo ${user.name}!`);
      history.push(INITIAL_PAGE_PATH);
    } catch (error) {
      console.log("[ERROR] [LoginPage.onSubmit]:", error);
      alert.error("Erro ao efetuar o login.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-form-container">
      <Image
        className="d-none d-md-block"
        src={themeContext.logo}
        style={{ margin: "auto" }}
        width={260}
      />
      <p className="text-explain text-muted">
        Complete o formulário abaixo com seu nome de usuário e sua senha para entrar no ConVert.
      </p>
      <hr />

      <Formik
        onSubmit={onSubmit}
        initialValues={{ usuario: '', senha: '' }}
        validationSchema={LoginValidation.schema}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="fg-usuario">
              <Form.Label>Usuário</Form.Label>
              <Form.Control
                name="usuario"
                type="text"
                placeholder="Digite seu nome de usuário..."
                onChange={handleChange}
                onBlur={handleBlur('usuario')}
                value={values.usuario}
                isValid={(touched.usuario && !errors.usuario)}
                isInvalid={(touched.usuario && !!errors.usuario)}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.usuario}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="fg-senha">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                name="senha"
                type="password"
                placeholder="Digite sua senha..."
                onChange={handleChange}
                onBlur={handleBlur('senha')}
                value={values.senha}
                isValid={(touched.senha && !errors.senha)}
                isInvalid={(touched.senha && !!errors.senha)}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">
                {errors.senha}
              </Form.Control.Feedback>
            </Form.Group>

            <hr />

            <Button className="form-button button-submit" type="submit" disabled={isLoading}>
              {isLoading ? <span>Entrando...</span> : (<><FaSignInAlt /> Entrar</>)}
            </Button>
          </Form>
        )}
      </Formik>

      <div className="login-with-google">
        <Button className="form-button button-google-login" onClick={handleGoogleAuth}>
          <svg id="icone-google" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 32 32" fill='#737679'>
            <path d="M 16.003906 14.0625 L 16.003906 18.265625 L 21.992188 18.265625 C 21.210938 20.8125 19.082031 22.636719 16.003906 22.636719 C 12.339844 22.636719 9.367188 19.664063 9.367188 16 C 9.367188 12.335938 12.335938 9.363281 16.003906 9.363281 C 17.652344 9.363281 19.15625 9.96875 20.316406 10.964844 L 23.410156 7.867188 C 21.457031 6.085938 18.855469 5 16.003906 5 C 9.925781 5 5 9.925781 5 16 C 5 22.074219 9.925781 27 16.003906 27 C 25.238281 27 27.277344 18.363281 26.371094 14.078125 Z"></path>
          </svg>
          Entrar com Google
        </Button>
      </div>

      <div className="links-container">
        <Link to={`${match.url}/esqueci`}>
          Esqueci minha senha
        </Link>
        <Link to={`${match.url}/cadastrar`}>
          Solicitar cadastro
        </Link>
      </div>
    </div>
  );
}

export default LoginPage;