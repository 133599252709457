import React, { useEffect, useState } from "react";
import ListLayout from "../../../../components/ListLayout";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import * as R from 'ramda'
import { functionsColumns } from "../ProjetoPrevistoViewInfo";

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function TableFuncoes({ currentTab, rowsDataFuncoes, openModalFunction, edit }) {
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);

    const transformNumber = (value) => {
        return Number(value?.replaceAll('.', '').replace(',', '.'));
    }

    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }

        const columnsHeader = functionsColumns;

        columnsHeader.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const getFunctionsRows = () => {
        let rows = rowsDataFuncoes.filter(item => item.horas[currentTab] !== undefined)
            .map(item => {
                const row = {
                    onClick: () => openModalFunction(item),
                    data: functionsColumns.map(col => {
                        if (col.name === 'horas') {
                            return {
                                value: transformNumber(item[col.name][currentTab]).toString().replace('.', ',')
                            }
                        }

                        if (col.name === 'taxaCustoVenda') {
                            return {
                                value: transformNumber(item[col.name])
                                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }

                        if (col.name === 'totalFuncaoMes') {
                            return {
                                value: Number(item[col.name][currentTab])
                                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }

                        return { value: item[col.name] };
                    })
                }
                return row;
            })

        const classNameTotal = ['font-weight-bold'];

        if (rows.length > 0) {
            const totais = rows.reduce((total, item) => {
                total[0] = R.sum([total[0], transformNumber(item.data[1].value)])
                total[1] = "" /* R.sum([total[1], Number(item.data[2].value.replace('R$', '').replaceAll('.', '').replace(',', '.'))]) */;
                total[2] = R.sum([total[2], Number(item.data[3].value.replace('R$', '').replaceAll('.', '').replace(',', '.'))]);

                return total;
            }, [0, 0, 0])

            rows.push({
                data: [
                    { value: 'Total', className: classNameTotal },
                    { value: totais[0].toString().replace('.', ','), className: classNameTotal },
                    { value: totais[1].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal },
                    { value: totais[2].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal}
                ]
            })
        }

        return rows;
    }

    useEffect(() => getFunctionsRows, [rowsDataFuncoes, currentTab])

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                <h4>Funções</h4>

                { edit &&
                    <Button onClick={() => openModalFunction()} disabled={!edit}><FaPlus /></Button>
                }
            </div>

            <ListLayout
                columns={getListHeader()}
                rows={getFunctionsRows()}
            />
        </>
    )
}

export default TableFuncoes;
